:root {
--deep_purple_A400_b0:#781bffb0;
--gray_50_01:#fdf9f8;
--deep_orange_A100:#eca06d;
--blue_gray_50:#ebeff4;
--blue_A400:#377cfd;
--light_blue_200:#78cbef;
--blue_A700:#2b59ff;
--gray_900_99:#000b3399;
--light_blue_600:#0a96d9;
--red_500:#ff4444;
--deep_purple_A400_05:#781bff05;
--amber_A200:#ffc83e;
--blue_A700_01:#1e6bff;
--green_500:#4dc247;
--gray_50:#f6fafc;
--white_A700_33:#ffffff33;
--green_A700:#17d85c;
--black_900:#000000;
--yellow_700:#e2b538;
--gray_900_a2:#000b33a2;
--blue_gray_900_01:#292d32;
--deep_purple_A200:#8c30f5;
--gray_900_63:#000b3363;
--blue_gray_900:#333333;
--red_A200_02:#ff4b5c;
--red_A200_01:#fd4c5c;
--teal_A700:#00bb98;
--blue_A700_93:#2b59ffee;
--gray_700:#666666;
--blue_900:#153abe;
--orange_A200:#ffa54c;
--blue_gray_400:#8083a3;
--white_A700_a2:#ffffffa2;
--red_A100:#ff8a73;
--red_A200:#ff685c;
--gray_900:#000b33;
--orange_500:#ff9900;
--amber_300:#ffcb65;
--amber_300_01:#ffd157;
--blue_A700_99:#1e6bff99;
--blue_A700_14:#2b59ff14;
--gray_100:#f2f2f2;
--white_A700_66:#ffffff66;
--black_900_33:#00000033;
--indigo_900:#001b7f;
--white_A700:#ffffff;
--indigo_600:#3c599b;
}
